import { commonValues } from '@/config/common.js';
import { useCurrencyStore } from '@/stores/currency';

export function useApiSeo() {
  const { $api } = useNuxtApp();
  const {
    t,
    locale,
  } = useI18n();
  const currencyStore = useCurrencyStore();
  const languageStore = useLanguageStore();

  const userCurrency = currencyStore.getCurrentCurrency;
  const userLanguage = languageStore.getCurrentLanguage;

  const defaultQuery = { currency: userCurrency?.code };

  const defaultHeaders = { 'Accept-Language': userLanguage?.tag };

  const defaultParams = {};

  const defaultResponse = {
    copy: '',
    description: t('t_DEFAULTSEODESCRIPTION'),
    title: t('t_DEFAULTSEOTITLE'),
    keywords: '',
    heading: null,
    ogTitle: t('t_DEFAULTSEOTITLE'),
    ogDescription: t('t_DEFAULTSEODESCRIPTION'),
    ogImage: commonValues.OGIMAGE,
    ogUrl: '',
    h1: '',
    h2: '',
  };

  const getGenericPageTags = async (page) => {
    let result = ref(null);

    const callKey = `getGenericPageTags${locale?.value}${page}${userCurrency?.code}`;
    const endpoint = `seo/generic/${page}/`;

    result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, defaultQuery, defaultParams));

    return result?.value?.data || defaultResponse;
  };

  const getHomePageTags = async () => {
    let result = ref(null);

    const callKey = `getHomePageTags${locale?.value}${userCurrency?.code}`;
    const endpoint = 'seo/homepage/';

    result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, defaultQuery, defaultParams));

    return result?.value?.data || defaultResponse;
  };

  const getWorldPageTags = async (propertyType) => {
    let result = ref(null);

    const internalQuery = {
      propertyType,
      ...defaultQuery,
    };

    const callKey = `getWorldPageTags${locale?.value}${propertyType}${userCurrency?.code}`;
    const endpoint = 'seo/worldwide/';

    result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, internalQuery, defaultParams));

    return result?.value?.data || defaultResponse;
  };

  const getContinentPageTags = async (propertyType, continentId) => {
    let result = ref(null);

    const internalQuery = {
      propertyType,
      ...defaultQuery,
    };

    const callKey = `getContinentPageTags${locale?.value}${propertyType}${continentId}${userCurrency?.code}`;
    const endpoint = `seo/continent/${continentId}/`;

    result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, internalQuery, defaultParams));

    return result?.value?.data || defaultResponse;
  };

  const getCountryPageTags = async (propertyType, countryId) => {
    let result = ref(null);

    const internalQuery = {
      propertyType,
      ...defaultQuery,
    };

    const callKey = `getCountryPageTags${locale?.value}${propertyType}${countryId}${userCurrency?.code}`;
    const endpoint = `seo/country/${countryId}/`;

    result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, internalQuery, defaultParams));

    return result?.value?.data || defaultResponse;
  };

  const getAreaPageTags = async (propertyType, areaId) => {
    let result = ref(null);

    const internalQuery = {
      propertyType,
      ...defaultQuery,
    };

    const callKey = `getAreaPageTags${locale?.value}${propertyType}${areaId}${userCurrency?.code}`;
    const endpoint = `seo/area/${areaId}/`;

    result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, internalQuery, defaultParams));

    return result?.value?.data || defaultResponse;
  };

  const getRegionPageTags = async (propertyType, regionId) => {
    let result = ref(null);

    const internalQuery = {
      propertyType,
      ...defaultQuery,
    };

    const callKey = `getRegionPageTags${locale?.value}${propertyType}${regionId}${userCurrency?.code}`;
    const endpoint = `seo/region/${regionId}/`;

    result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, internalQuery, defaultParams));

    return result?.value?.data || defaultResponse;
  };

  const getCityPageTags = async (propertyType, cityId) => {
    let result = ref(null);

    const internalQuery = {
      propertyType,
      ...defaultQuery,
    };

    const callKey = `getCityPageTags${locale?.value}${propertyType}${cityId}${userCurrency?.code}`;
    const endpoint = `seo/city/${cityId}/`;

    result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, internalQuery, defaultParams));

    return result?.value?.data || defaultResponse;
  };

  const getDistrictPageTags = async (propertyType, districtId) => {
    let result = ref(null);

    const internalQuery = {
      propertyType,
      ...defaultQuery,
    };

    const callKey = `getDistrictPageTags${locale?.value}${propertyType}${districtId}${userCurrency?.code}`;
    const endpoint = `seo/district/${districtId}/`;

    result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, internalQuery, defaultParams));

    return result?.value?.data || defaultResponse;
  };
  const getPropertyPageTags = async (propertyType, propertyId) => {
    let result = ref(null);

    const internalQuery = {
      propertyType,
      ...defaultQuery,
    };

    const callKey = `getPropertyPageTags${locale?.value}${propertyType}${propertyId}${userCurrency?.code}`;
    const endpoint = `seo/microsite/${propertyId}/`;

    result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, internalQuery, defaultParams));

    return result?.value?.data || defaultResponse;
  };

  return {
    getGenericPageTags,
    getHomePageTags,
    getWorldPageTags,
    getContinentPageTags,
    getCountryPageTags,
    getAreaPageTags,
    getRegionPageTags,
    getCityPageTags,
    getDistrictPageTags,
    getPropertyPageTags,
  };
}
